@import "../../vars";

.footer {
  background: #3EB17A;
  color: #fff;
  padding: 12px 0;
  font-weight: 300;
  font-size: 20px;
  line-height: 38px;
  margin-top: 60px;

  @media(max-width: $lg) {
    font-size: 18px;
  }

  @media(max-width: $md) {
    line-height: 25px;
    text-align: center;
  }

  &__container {
    @include container;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: $md) {
      flex-direction: column;
    }
  }

  &__logo {
    cursor: pointer;
  }

  &__social {
    display: inline-flex;
    margin-top: 10px;
    margin-left: 0;

    @media (min-width: $md) {
      margin-top: 0;
      margin-left: 20px;
    }

    &__icon {
      margin: 0 10px;
    }
  }

  &__mail {
    margin-right: auto;
    margin-left: 50px;

    a {
      color: #fff;
      text-decoration: none;
    }

    @media(max-width: $lg) {
      margin-left: 25px;
    }

    @media(max-width: $md) {
      margin: 10px 0 0;
    }
  }

  &__text {
    @media (min-width: $md) {
      line-height: normal;
      text-align: center;
      margin-left: 20px;
    }
  }
}
