@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/HelveticaNeue.eot');
  src: url('../public/fonts/HelveticaNeue.eot?iefix') format('eot'),
  url('../public/fonts/HelveticaNeue.woff') format('woff'),
  url('../public/fonts/HelveticaNeue.ttf') format('truetype'),
  url('../public/fonts/HelveticaNeue.svg#webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/HelveticaNeue-Thin.eot');
  src: url('../public/fonts/HelveticaNeue-Thin.eot?iefix') format('eot'),
  url('../public/fonts/HelveticaNeue-Thin.woff') format('woff'),
  url('../public/fonts/HelveticaNeue-Thin.ttf') format('truetype'),
  url('../public/fonts/HelveticaNeue-Thin.svg#webfont') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/HelveticaNeue-Light.eot');
  src: url('../public/fonts/HelveticaNeue-Light.eot?iefix') format('eot'),
  url('../public/fonts/HelveticaNeue-Light.woff') format('woff'),
  url('../public/fonts/HelveticaNeue-Light.ttf') format('truetype'),
  url('../public/fonts/HelveticaNeue-Light.svg#webfont') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Hiragino';
  src: url('../public/fonts/hiragino-maru-gothic-pron-w4.otf');
  font-weight: normal;
  font-style: normal;
}

@import "~bootstrap/scss/bootstrap";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: normal;
  color: #000;
}

body,
h1, h2, h3, h4, h5, h6,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
