@import "../../vars";

.portfolio-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  //padding: 0;

  &__container {
    text-align: center;
    cursor: pointer;
    width: 90%;
  }

  &__link {
    text-decoration: none;
    color: #000000;

    &:hover {
      text-decoration: none;
      color: #000000;
    }
  }

  &__img {
    text-align: center;
    height: auto;

    //@media (max-width: $sm) {
    //  height: 180px;
    //}

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid lightgray;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    }
  }

  &__title {
    margin-top: 40px;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

    @media (max-width: $sm) {
      font-size: 25px;
      line-height: 25px;
    }
  }

  &__details {
    background-color: #3eb17a;
    border: 0;
    border-radius: 36px;
    padding: 16px 100px;
    font-weight: 300;
    font-size: 26px;
    line-height: 38px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    transition: box-shadow 0.3s ease;
    margin-top: 25px;

    @media (max-width: $sm) {
      font-size: 20px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      box-shadow: 0 0 20px rgba(62, 177, 122, 1);
    }
  }
}

.modal-backdrop {
  background-color: rgb(36, 36, 36);

  &.show {
    opacity: 0.7;
  }
}
