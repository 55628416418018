@import "./vars";

.header {
  height: 100vh;
  background-image: url("../public/assets/imgs/header-bg-new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;

  &__container {
    @include container;

    height: 422px;
    margin-top: auto;
    margin-bottom: 30vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    @media (max-height: $lg) {
      margin-bottom: 10vh;
    }

    @media (max-width: $lg) {
      margin-bottom: auto;
      text-align: center;
    }
  }

  &__block1,
  &__block2 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-weight: 700;
    font-size: 84px;
    line-height: 107px;
    text-transform: uppercase;
    margin-bottom: 20px;

    @media (max-width: $lg) {
      max-width: 100%;
      font-size: 50px;
      line-height: 60px;
    }

    @media (max-width: $sm) {
      font-size: 60px;
      line-height: 70px;
    }

    @media (max-width: 380px) {
      font-size: 50px;
      line-height: 60px;
    }
  }

  &__subtitle {
    max-width: 886px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    @media (max-width: $sm) {
      font-size: 24px;
      line-height: 30px;
    }

    @media (max-width: 380px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__contact {
    width: 243px;
    height: 64px;
    background-color: #3eb17a;
    font-size: 1.5em;
    text-transform: uppercase;
    color: white;
    border: none;
    border-radius: 32px;

    @media (max-width: $lg) {
      margin-top: 2em;
      align-self: center;
    }
  }

  &__scroll {
    cursor: pointer;
    position: absolute;
    bottom: 60px;
    left: 50%;
    margin-left: -38px;
    transition: transform 0.3s;
    -webkit-animation: pulsing 2s infinite;
    animation: pulsing 2s infinite;

    @-webkit-keyframes pulsing {
      0% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
      }
      50% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }
      100% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
      }
    }
    @keyframes pulsing {
      0% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
      }
      50% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }
      100% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
      }
    }
  }
}

.navigation {
  padding: 12px 0;
  //border-bottom: 1px solid rgba(52, 141, 96, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;

  &--is-active {
    background: #348d60;

    .navigation__item:hover {
      color: #fff;
    }

    .navigation__inner {
      background: #348d60;
    }
  }

  &__container {
    @include container;

    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    margin-right: auto;
    cursor: pointer;

    img {
      width: 160px;
      height: 91px;
    }
  }

  &__menu {
    display: none;
    width: 50px;
    height: 10px;
    margin: auto 10px;
    cursor: pointer;
    position: relative;
    top: -15px;

    &:after,
    &:before {
      content: "";
      width: 50px;
      height: 10px;
      background: #fff;
      display: block;
      position: absolute;
      top: 15px;
    }

    &__burger {
      background: #fff;
      &:before {
        top: 30px;
      }
    }

    &__close {
      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &--is-open {
        + .navigation__inner {
          transform: translateX(0);
        }
      }
    }

    @media (max-width: $lg) {
      display: flex;
      flex-direction: column;
    }
  }

  &__close {
    display: none;
    width: 50px;
    height: 10px;
    margin: auto 10px;
    cursor: pointer;
    position: relative;
    top: -15px;

    &:after,
    &:before {
      content: "";
      width: 50px;
      height: 10px;
      background: #fff;
      display: block;
      position: absolute;
      top: 15px;
    }

    @media (max-width: $lg) {
      display: flex;
      flex-direction: column;
    }
  }

  &__inner {
    display: flex;

    @media (max-width: $lg) {
      flex-direction: column;
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      padding: 5px 0;
      background: rgba(0, 0, 0, 0.9);
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      transition: transform 0.3s ease;
      transform: translateX(100%);
    }
  }

  &__item {
    color: #fff;
    font-weight: 300;
    font-size: 20px;
    text-decoration: none;
    line-height: 19px;
    margin-left: 30px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #01ff7a;
      text-decoration: none;
      transition: color 0.5s ease;

      //&:before {
      //  height: 5px;
      //}
    }

    &:before {
      content: "";
      width: 100%;
      height: 0;
      background: rgba(255, 255, 255, 0.3);
      position: absolute;
      bottom: -10px;
      transition: height 0.3s ease;
    }

    &--is-active {
      &:before {
        height: 5px;
      }
    }

    @media (max-width: $lg) {
      margin-left: 20px;
    }

    @media (max-width: $lg) {
      justify-content: center;
      margin: 0;
      padding: 5px 10px;
      font-size: 20px;
      height: 44px;

      &:before {
        display: none;
      }
    }
  }
}

.about-us {
  padding-top: 50px;
  padding-bottom: 45px;

  &__container {
    @include container;
  }

  &__title {
    @include section-title;
  }

  &__subtitle {
    @include section-subtitle;
  }

  &__inner {
    display: flex;
    align-items: center;
    margin-top: 50px;

    @media (max-width: $lg) {
      flex-direction: column;
    }
  }

  &__description {
    background-color: #f1f2f2;
    padding: 70px 65px 70px 30px;
    position: relative;

    &:after {
      content: "";
      width: 60px;
      height: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      background-color: #f1f2f2;
    }

    @media (max-width: $xl) {
      padding-right: 30px;
    }

    @media (max-width: $lg) {
      padding: 30px 15px;

      &:after {
        display: none;
      }
    }
  }

  &__img {
    position: relative;
    width: 549px;

    @media (max-width: $lg) {
      max-width: 100%;
    }
  }

  &__paragraph {
    font-weight: 200;
    font-size: 20px;
    line-height: 34px;

    &--shifted {
      max-width: 300px;
      width: 100%;
      margin-left: auto;

      @media (max-width: $xl) {
        margin-left: 0;
      }

      @media (max-width: $lg) {
        max-width: 100%;
        flex: 1;
      }
    }

    @media (max-width: $sm) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-top: 60px;
    position: relative;

    @media (max-width: $xl) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
    }

    @media (max-width: $lg) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }

  &__year {
    font-weight: bold;
    font-size: 90px;
    line-height: 34px;
    color: #3eb17a;
    border: 1px solid #3eb17a;
    width: 225px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    position: absolute;
    left: -92px;

    @media (max-width: $xl) {
      position: static;
      margin-bottom: 20px;
    }

    @media (max-width: $sm) {
      margin-right: 0;
      font-size: 45px;
      width: 100%;
      height: 100px;
    }
  }

  &__bold {
    font-weight: 600;
  }

  &__quote {
    display: flex;
    text-align: right;
    margin-top: 50px;
    justify-content: flex-end;
    font-weight: 300;
    font-size: 24px;
    line-height: 38px;

    &-right {
      margin-top: 15px;
      margin-right: 5px;

      @media (max-width: $sm) {
        font-size: 50px;
        margin-top: 0;
        margin-right: 0;
      }
    }

    &-left {
      margin-top: 80px;
      margin-bottom: -30px;

      @media (max-width: $sm) {
        margin-top: 96px;
      }
    }

    span {
      font-family: Hiragino, sans-serif;
      font-size: 100px;
      color: #3eb17a;
    }

    @media (max-width: $sm) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__italic {
    font-style: italic;
  }
}

.services {
  height: max-content;
  padding-top: 10px;
  margin: 5em 0 10em 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $lg) {
    padding-top: 20px;
    margin: 5em 0;
  }

  @media (max-width: $md) {
    margin: 2em 0;
  }

  &__container {
    @include container;
    max-width: 1400px;

    .services__title {
      @include section-title;

      span {
        display: inline-block;
        transform: skewX(-10deg);
        background-color: #3eb17a;
        border-radius: 10px;
        padding: 5px 10px;
        color: white;
      }

      h2 {
        transform: skewX(10deg);
      }
    }

    .services__subtitle {
      @include section-subtitle;
      max-width: 475px;
      font-size: 30px;

      @media (max-width: $md) {
        max-width: 435px;
      }
    }

    .services__label {
      width: max-content;
      position: relative;
      transform: rotate(270deg) translateX(-113%) translateY(-20%);
      transform-origin: bottom left;
      background-color: #3eb17a;
      padding: 5px 10px;
      border-radius: 3px;
      color: white;

      @media (max-width: $xl) {
        display: none;
      }
    }
  }

  &__wrapper {
    display: grid;
    grid-template: 1fr / 4fr 6fr;

    @media (max-width: $lg) {
      height: max-content;
      grid-template: 2fr 2fr/ 1fr;
    }

    @media (max-width: $md) {
      grid-template: 3fr 2fr/ 1fr;
    }

    &__buttons {
      grid-area: 1/1/2/2;
      min-width: 160px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: $lg) {
        grid-area: 2/1/3/2;
        display: grid;
        grid-template: 4em 4em 4em 4em 4em/ 1fr;
        gap: 10px;
      }

      @media (max-width: $md) {
        grid-area: 2/1/3/2;
        display: grid;
        grid-template: 3em 3em 3em 3em 3em/1fr;
        gap: 0;
      }

      .activeButton {
        background-color: black;
        color: white;
        cursor: pointer;
        border: none;
        transition: all 0.3s;

        .span-num {
          color: #3eb17a;
        }
      }

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 4em;
        margin: 1em;
        padding: 1.5em;
        border: 2px solid #ececec;
        border-radius: 2em;
        font-weight: bold;
        background-color: white;
        font-size: 1.3em;

        @media (max-width: $lg) {
          height: 1em;
        }

        @media (max-width: $md) {
          margin: 0 1em;
          height: 1em;
          font-size: 0.8em;
        }

        .arrow {
          font-size: 20px;
          padding-bottom: 3px;
        }

        &:hover {
          background-color: black;
          color: white;
          cursor: pointer;
          border: none;
          transition: all 0.3s;

          .span-num {
            color: #3eb17a;
          }
        }
      }
    }

    &__block {
      grid-area: 1/2/2/3;
      display: grid;
      grid-template: 1fr 1fr 1fr/ 1fr 1fr 1fr 1fr 1fr;
      gap: 1em;
      margin: 1em;

      @media (max-width: $lg) {
        grid-area: 1/1/2/2;
        grid-template: 240px 120px/ 1fr 1fr 1fr;
      }

      @media (max-width: $md) {
        grid-area: 1/1/2/2;
        grid-template: 170px max-content 70px/ 1fr;
        gap: 0.5em;
      }

      &__image {
        grid-area: 1/1/4/4;
        border-radius: 1.5em;
        overflow: hidden;

        @media (max-width: $lg) {
          grid-area: 1/1/2/4;
        }

        @media (max-width: $md) {
          grid-area: 1/1/2/2;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &__description {
        grid-area: 1/4/3/6;
        border: 2px solid #ececec;
        border-radius: 1.5em;
        background-color: #2a2a2a;
        color: white;
        padding: 1.2em;
        font-size: 1.5em;

        @media (max-width: $lg) {
          font-size: 1.2em;
          grid-area: 2/1/3/3;
        }

        @media (max-width: $md) {
          grid-area: 2/1/3/2;
        }
      }

      &__link {
        text-decoration: none;
        grid-area: 3/4/4/6;
        border-radius: 1.5em;
        background-color: #3eb17a;
        padding: 1.2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        border: none;

        @media (max-width: $lg) {
          grid-area: 2/3/3/4;
          align-items: center;
          transition: none !important;
        }

        @media (max-width: $md) {
          grid-area: 3/1/4/2;
          align-items: center;
        }

        &:hover {
          transform: scale(1.05);
          transition: all 0.3s;
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
        }

        &:active {
          transform: scale(0.95);
        }

        p {
          font-size: 14px;
          color: white;
          margin-bottom: 10px;

          @media (max-width: $lg) {
            display: none;
          }
        }

        .link-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: white;
          margin: 1em 0;

          h4 {
            width: 5em;
          }

          .a-arrow {
            padding: 2px 10px;
            background-color: white;
            width: 46px;
            height: 46px;
            border: 1px solid whitesmoke;
            border-radius: 50%;
            transform: rotate(-30deg);
            font-size: 26px;
            color: black;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}

.technologies {
  background-color: #3eb17a;
  padding-top: 60px;
  padding-bottom: 60px;
  color: #fff;
  text-align: center;

  &__container {
    @include container;
  }

  &__title {
    @include section-title-technology;
  }

  &__subtitle {
    @include section-subtitle;

    max-width: 600px;
  }

  &__infocard {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
  }

  &__contact {
    cursor: pointer;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: black;
    border: 1px solid #fff;
    background-color: white;
    outline: none;
    padding: 20px 100px;
    border-radius: 38px;
    transition: box-shadow 0.3s ease;

    &:focus {
      outline: none;
    }

    &:hover {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.4) inset;
    }
  }
}

.portfolio {
  padding-top: 60px;

  &__container {
    @include container;
    max-width: 1430px;
  }

  &__title {
    @include section-title;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  }

  &__inner {
    .swiper-container,
    .swiper {
      padding: 30px 40px 60px 40px;

      @media (max-width: $sm) {
        padding-top: 30px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      &:after {
        display: none;
      }
    }

    .swiper-button-next {
      right: -5px;
    }

    .swiper-button-prev {
      left: -5px;
    }
  }
}

.work-with {
  padding-top: 90px;
  padding-bottom: 90px;
  color: #fff;
  background: url("../public/assets/imgs/waf_logo_white.png") #3eb17a no-repeat
    35px 95%;

  @media (max-width: $sm) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__container {
    max-width: 1600px;
    margin: 0 auto;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $lg) {
      flex-direction: column;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: $xl) {
      max-width: 700px;
    }

    @media (max-width: $sm) {
      max-width: 500px;

      font-size: 25px;
      line-height: 30px;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 26px;
    line-height: 38px;
    text-transform: uppercase;
    position: relative;
    margin: 10px;
    text-align: center;

    @media (max-width: $sm) {
      font-size: 20px;
      line-height: 25px;
      margin: 10px;
    }
  }

  &__item {
    border: 1px solid white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 2.5em;
      margin-left: 2em;
      //margin-right: 1em;
      filter: invert(1);

      @media (max-width: $lg) {
        margin-left: 0.8em;
        margin-right: 0.7em;
      }

      @media (max-width: $sm) {
        margin-left: 0.4em;
        margin-right: 0.4em;
      }
    }

    @media (max-width: $xl) {
      width: 260px;
    }

    @media (max-width: $lg) {
      margin-bottom: 15px;
      justify-content: start;
    }

    @media (max-width: $sm) {
      width: 100%;
      height: 80px;
      margin-bottom: 10px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      transition: all 0.3s;
      cursor: pointer;

      .description-item1,
      .description-item2,
      .description-item3,
      .description-item4,
      .description-item5,
      .description-item6,
      .description-item7,
      .description-item8,
      .description-item9 {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease;
      }
    }
  }

  &__box {
    margin: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr 1fr;
    //justify-content: center;

    .item1 {
      grid-column: 1/3;
    }
    .item2 {
      grid-column: 3/5;
    }
    .item3 {
      grid-column: 5/7;
    }
    .item4 {
      grid-column: 7/9;
    }
    .item5 {
      grid-column: 9/11;
    }
    .item6 {
      grid-column: 2/4;
    }
    .item7 {
      grid-column: 4/6;
    }
    .item8 {
      grid-column: 6/8;
    }
    .item9 {
      grid-column: 8/10;
    }

    @media (max-width: $xl) {
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr;
      .item4 {
        grid-column: 2/4;
      }
      .item5 {
        grid-column: 4/6;
      }
      .item6 {
        grid-column: 6/8;
      }
      .item7 {
        grid-column: 1/3;
      }
      .item8 {
        grid-column: 3/5;
      }
      .item9 {
        grid-column: 5/7;
      }
    }

    @media (max-width: $lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      .item1,
      .item3,
      .item5,
      .item7 {
        grid-column: 1/3;
      }
      .item2,
      .item4,
      .item6,
      .item8 {
        grid-column: 3/5;
      }
      .item9 {
        grid-column: 2/4;
      }
    }
  }

  &__caption {
    font-weight: 500;
    font-size: 1.3em;
    padding: 10px 10px 10px 0;
    width: 12em;
    text-align: center;

    @media (max-width: $sm) {
      font-size: 1rem;
      line-height: 18px;
    }
  }

  .description-item1,
  .description-item2,
  .description-item3,
  .description-item4,
  .description-item5,
  .description-item6,
  .description-item7,
  .description-item8,
  .description-item9 {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transform: translateY(-100px) translateX(-10px);
    max-width: 14em;
    height: auto;
    background-color: white;
    color: black;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.4);
  }
}

.team {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: $xl) {
    overflow: hidden;
  }

  &__container {
    @include container;
  }

  &__title {
    @include section-title;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: -20px;

    @media (max-width: $xl) {
      justify-content: center;
    }
  }

  &__description {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-top: 60px;

    @media (max-width: $sm) {
      margin-top: 10px;
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.contact-us {
  &__container {
    @include container;
  }

  &__inner {
    background: #f1f2f2;
    padding-top: 60px;
    padding-left: 60px;
  }
}
