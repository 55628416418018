@import "../../vars";

.popup {
  max-width: 100%;
  margin: 0;

  &__inner {
    position: relative;
    padding: 50px 50px 60px;
    border: 0;
    border-radius: 0;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
      top: 0;
      left: 0;
    }

    @media (max-width: $lg) {
      padding: 30px 15px;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    height: 18px;
    width: 18px;
    display: flex;

    &:hover {
      svg {
        opacity: 1;
      }
    }

    svg {
      vertical-align: top;
      fill: #fff;
      opacity: 0.8;
      transition: all 0.2s ease;
    }
  }

  &__title {
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    color: #242424;
    text-align: center;

    @media (max-width: $sm) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &__body {
    padding: 0;
    width: 100%;
    max-width: 900px;

    .swiper-button-next,
    .swiper-button-prev {
      &:after {
        display: none;
      }
    }

    .swiper-button-next {
      right: 0;

      @media (max-width: $sm) {
        right: -5px;
      }
    }

    .swiper-button-prev {
      left: 0;

      @media (max-width: $sm) {
        left: -5px;
      }
    }
  }

  &__browser {
    background: #edf1f2;
    border-radius: 4px 4px 0 0;
    padding: 20px 10px 10px;
    margin-top: 25px;
  }

  &__search-bar {
    display: flex;
    align-items: center;
  }

  &__circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 7px;

    &--close {
      background: #e60101;
    }

    &--restore {
      background: #ffcc17;
    }

    &--minimize {
      background: #b7d112;
    }
  }

  &__bar {
    flex: 1;
    height: 30px;
    background: #fff;
    border-radius: 4px;
    margin: 0 38px 0 34px;
  }

  &__item {
    text-align: center;
  }

  &__img {
    margin-top: 12px;
    max-width: 90%;
    max-height: 550px;

    @media (max-width: $sm) {
      max-width: 82%;
    }
  }

  &__img-no-slide {
    margin-top: 12px;
    max-width: 95%;
  }

  &__link {
    background: #3eb17a;
    display: block;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-weight: 300;
    font-size: 26px;
    line-height: 31px;
    padding: 20px;
    transition: box-shadow 0.3s ease;

    @media (max-width: $sm) {
      font-size: 20px;
      padding: 10px;
    }

    &:hover {
      text-decoration: none;
      color: #fff;
      box-shadow: 0 0 20px rgba(62, 177, 122, 1);
    }
  }

  &__mission {
    margin-top: 25px;
    color: #242424;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;

    @media (max-width: $sm) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__paragraph {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    margin-top: 15px;

    @media (max-width: $sm) {
      font-size: 18px;
      line-height: 25px;
      margin-top: 10px;
    }
  }

  &__technologies {
    margin-top: 45px;

    @media (max-width: $sm) {
      margin-top: 25px;
    }
  }

  &__info {
    margin-top: 15px;

    @media (max-width: $lg) {
      flex-wrap: wrap;
    }
  }

  &__part {
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    color: #242424;
  }

  &__part + &__part {
    @media (max-width: $sm) {
      margin-top: 10px;
    }
  }

  &__part-title {
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
    display: flex;
    height: 50px;
    align-items: flex-end;

    @media (max-width: $sm) {
      height: auto;
    }
  }

  &__part-description {
    margin-top: 10px;

    @media (max-width: $sm) {
      margin-top: 5px;
    }
  }

  &__contact {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @media (max-width: $sm) {
      flex-direction: column;
      margin-top: 30px;
    }
  }

  &__exit,
  &__email {
    font-weight: 300;
    font-size: 26px;
    line-height: 31px;
    text-transform: uppercase;
    padding: 20px 70px;
    transition: box-shadow 0.3s ease;
    border: none;
    outline: none;

    &:focus {
      outline: none;
    }

    @media (max-width: $lg) {
      padding: 20px 60px;
    }

    @media (max-width: $sm) {
      padding: 10px 0;
      width: 100%;
      text-align: center;
      margin-top: 10px;
      font-size: 20px;
    }
  }

  &__exit {
    margin-right: 20px;
    color: rgb(44, 41, 41);
    background: #fff;

    &:hover {
      text-decoration: none;
      box-shadow: 0 0 20px rgb(180, 180, 180);
    }
  }

  &__email {
    color: #fff;
    background: #3eb17a;

    &:hover {
      text-decoration: none;
      color: #fff;
      box-shadow: 0 0 20px rgba(62, 177, 122, 1);
    }
  }
}
