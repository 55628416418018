$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin container {
	max-width: 1130px;
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;
}

@mixin section-title {
	font-weight: 300;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	color: #242424;
	position: relative;

	&:after {
		content: '';
		width: 60px;
		height: 1px;
		background-color: #3EB17A;
		display: block;
		margin: 20px auto 0;
	}

	@media(max-width: $sm) {
		font-size: 30px;
		line-height: 35px;
	}
}

@mixin section-subtitle {
	font-weight: 200;
	font-size: 26px;
	line-height: 38px;
	text-align: center;
	margin: 20px auto 0;
	max-width: 435px;

	@media(max-width: $sm) {
		font-size: 20px;
		line-height: 25px;
	}
}

@mixin section-title-technology {
	@include section-title;

	color: #fff;

	&:after {
		background-color: #fff;
	}
}
