@import "../../vars";

.person-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  margin-right: 20px;
  width: 260px;

  @media (max-width: $sm) {
    margin-bottom: 20px;
  }

  &__photo {
    width: 100%;
    height: 260px;
    object-fit: cover;
    filter: grayscale(1);
  }

  &__name {
    margin-top: 20px;
    font-weight: 500;
    font-size: 26px;
    line-height: 38px;
    color: #242424;

    @media (max-width: $sm) {
      font-size: 20px;
      line-height: 25px;
      margin-top: 10px;
    }
  }

  &__profession {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #3eb17a;
    text-align: center;
    margin-top: 10px;
    padding: 0 10px;

    @media (max-width: $sm) {
      margin-top: 5px;
    }
  }
}
