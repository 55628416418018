@import "../../vars";

.infocard {
  width: 33%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 60px;
  padding: 0 10px;

  @media(max-width: $md) {
    width: 50%;
  }

  @media(max-width: $sm) {
    width: 100%;
  }

  &__title {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    margin-top: 20px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: $sm) {
      height: auto;
      font-size: 22px;
      line-height: 25px;
    }
  }

  &__text {
    max-width: 335px;
    margin: 20px auto 0;

    @media(max-width: $sm) {
      margin-top: 10px;
    }
  }
}
